<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :setting-columns="columns"
      :filters.sync="filters"
      :endpoint-list="ENDPOINT.ORDER_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-sm-12">
                  <label>{{ filters.status_delivered.label }}</label>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                  <app-radio v-for="(item, index) in ORDER_LIST_CONSTANT.FILTER_STATUS_DELIVERED"
                             :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                             :val="item.id" v-model="filters.status_delivered.value"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-select :name="filters.apartment_id_equal.name"
                              input-style="normal"
                              v-model="filters.apartment_id_equal.value"
                              :options-data="meta.apartments" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label>{{ filters.order_number.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-input :name="filters.order_number.name" input-style="normal"
                             v-model="filters.order_number.value"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4 pl-0">
            <div class="form-group start_date">
              <label>{{ filters.menu_id_equal.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12">
                  <app-select :name="filters.menu_id_equal.name"
                              input-style="normal"
                              v-model="filters.menu_id_equal.value"
                              :options-data="meta.menus" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label>{{ filters.customer_name.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-input :name="filters.customer_name.name" input-style="normal"
                             v-model="filters.customer_name.value"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group start_date">
              <label>{{ filters.customer_phone.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                  <app-input :name="filters.customer_phone.name"
                             type="phone"
                             input-style="normal"
                             placeholder="00000000000"
                             v-model="filters.customer_phone.value"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4 pl-0">
            <div class="form-group start_date">
              <label class="col-xl-4 col-lg-6 col-sm-8">{{ filters.start_date.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12">
                  <app-date-range-picker
                    :rules="`from_date_time_list_page:${filters.close_date.value}`"
                    :name="filters.start_date.name"
                    v-model="filters.start_date.value"
                    format="YYYY-MM-DD H:mm"
                    :timePickerMinutes="true"
                    @input="filters.start_date.value = $event"/>
                </div>
                <span class="align-self-center align-items-center mr-lg-2 mr-xl-5 " style="font-size: 30px">~</span>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-4 col-sm-8" style="opacity: 0">1</label>
              <div class="col-xl-9 col-lg-12 col-sm-12 pl-0">
                <app-date-range-picker
                  :name="filters.close_date.name"
                  v-model="filters.close_date.value"
                  format="YYYY-MM-DD H:mm"
                  :timePickerMinutes="true"
                  @input="filters.close_date.value = $event"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>
      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.apartment_name" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row.apartment_id)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.apartment_name}}
          </p>
        </td>
      </template>
      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            詳細
          </button>
        </td>
      </template>
      <template v-slot:body-cell-menu_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.menu_name" style="color: #007bff;"
             @click="handlerMenuNameClick(props.row.menu_id)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.menu_name}}
          </p>
        </td>
      </template>
      <template v-slot:body-cell-status_delivered="props">
        <td class="app-align-middle">
          <p class=" app-table-p app-cell-tooltip mb-0">
            <app-select @change="updateStatus($event ,props.row.id)"
                        input-style="normal"
                        v-model="props.row.status_delivered"
                        :options-data="ORDER_LIST_CONSTANT.STATUS_DELIVERED" />
          </p>
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  import {PLAN_CONSTANT} from "../../../constants/plan";
  import {ORDER_LIST_CONSTANT} from "../../../constants/order_list";
  export default {
    name: "PlanListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          status_delivered: {
            name: "status_delivered",
            condition: "equal",
            label: this.$t("order_list.status_delivered")
          },
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("order_list.mansion")
          },
          order_number: {
            name: "order_number_like",
            condition: "like",
            label: this.$t("order_list.order_number")
          },
          menu_id_equal: {
            name: "menu_id",
            condition: "equal",
            label: this.$t("order_list.menu")
          },
          customer_name: {
            name: "customer_name_like",
            condition: "like",
            label: this.$t("order_list.customer_name")
          },
          customer_phone: {
            name: "customer_phone_like",
            condition: "like",
            label: this.$t("order_list.customer_phone")
          },
          start_date: {
            name: "start_time_between",
            condition: "between",
            label: this.$t("order_list.datetime_order")
          },
          close_date: {
            name: "close_time_between",
            condition: "between",
            label: this.$t("reservation.status")
          },
        },
        columns: [
          {name: "id", label: this.$t("plans.id"), sortable: true, textAlign: 'text-center'},
          {name: "status_delivered", label: this.$t("order_list.status_delivered"), sortable: true, textAlign: 'text-center'},
          {name: "apartment_id", label: this.$t("order_list.mansion"), sortable: true},
          {name: "order_number", label: this.$t("order_list.order_number"), sortable: true},
          {name: "date", label: this.$t("order_list.date_order"),sortable: true ,textAlign: 'text-center'},
          {name: "hour", label: this.$t("order_list.hour_order"), textAlign: 'text-center'},
          {name: "menu_id", label: this.$t("order_list.menu"), sortable: true, textAlign: 'text-center'},
          {name: "customer_name", label: this.$t("order_list.customer_name"), sortable: true},
          {name: "customer_phone", label: this.$t("order_list.customer_phone"),  textAlign: 'text-center'},
          {name: "edit", label: '詳細', textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
        entry : {}
      };
    },
    watch : {
      'filters.apartment_id_equal.value': function(value, oldValue) {
        this.getMetaData({apartment_id : value})
      },
      'filters.start_date.value' : async function(value) {
          await this.$refs.form_create_entry.validate()
      },
      'filters.close_date.value' : async function(value) {
          await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.ROOMSERVICE_ORDER_CREATE})
      },
      async handlerCancelButton() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.FACILITY_LIST,
          query: {'filters.status_disp_crowded.equal': 'all', 'perPage' : '99999'}
        });
      },
      handlerApartmentNameClick(apartmentID) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartmentID}});
        window.open(routeApartment.href, '_blank');
      },
      handlerMenuNameClick(menuId) {
        let routeMenu = this.$router.resolve({name: this.ROUTES.ADMIN.MENU_EDIT, params: {id: menuId}});
        window.open(routeMenu.href, '_blank');
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.ROOMSERVICE_ORDER_EDIT, params: {id: entry.id}})
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.status_delivered.equal' : 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      getStatus(typeID) {
        let type = ORDER_LIST_CONSTANT.STATUS_DELIVERED.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      getMetaData(params) {
        this.$request.get(this.ENDPOINT.UI_HELPER_ORDER_LIST, params).then(res => {
          this.meta = {
            apartments: res.data.apartments,
            menus: res.data.menus,
          }
          this.meta.apartments.unshift({
            id: "",
            name: ""
          })
          this.meta.menus.unshift({
            id: "",
            name: ""
          })
        })
      },
      async updateStatus(e, id) {
        await this.$request.get(this.ENDPOINT.ORDER_LIST_EDIT(id)).then(res => {
          if (res.hasErrors()) {
            return ;
          }
          this.entry = res.data.data
        })

        if (this.entry.status_delivered != e) {
          let res = null;
          let msg = "";
          res = await this.$request.patch(this.ENDPOINT.ORDER_LIST_EDIT(id), {
            status_delivered : e
          });
          msg = this.$t("common.msg update ok")
          if (res.hasErrors()) {
            this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.__noticeSuccess(msg);
          }
        }
      }
    },
    mounted: function () {
      // this.getMetaData();
    },
  }
</script>

<style scoped>

</style>
